@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'NotoSansKR-Thin';
    src: url('font/NotoSansKR-Thin.woff') format('woff');
    unicode-range: U+AC00-U+D7A3, U+0030-0039;

}

@font-face {
    font-family: 'NotoSansKR-Regular';
    src: url('font/NotoSansKR-Regular.woff') format('woff');
    unicode-range: U+AC00-U+D7A3;
}

@font-face {
    font-family: 'NotoSansKR-Medium';
    line-height: 1.5;
    src: url('font/NotoSansKR-Medium.woff') format('woff');
    unicode-range: U+AC00-U+D7A3;
}

@font-face {
    font-family: 'Pretendard-Regular';
    src: url('font/Pretendard-Regular.woff') format('woff');
    unicode-range: U+AC00-U+D7A3;
}

@font-face {
    font-family: 'Pretendard-Medium';
    line-height: 1.5;
    src: url('font/Pretendard-Medium.woff') format('woff');
    unicode-range: U+AC00-U+D7A3;
}

@font-face {
    font-family: 'Helvetica';
    src: url('font/Helvetica.woff') format('woff');
    unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
}

@layer base {
    html {
        @apply font-main
    }

    body #root {
        @apply whitespace-pre-wrap;
    }

    /* ::-webkit-scrollbar {
        @apply web:block phone:hidden ;
    } */

    h3 {
        font-size: 16px;
        font-weight: bold;
    }

    ol {
        display: block;
        list-style-type: decimal;
        padding-left: 30px;
    }

    ol>li {
        counter-increment: num;
        content: counter(num);
        display: list-item;
    }

    ol>li>ol {
        list-style-type: none;
        padding-left: 10px;
    }

    table {
        width: 100%;
    }

    textarea {
        resize: none;
    }

    table,
    th,
    td {
        @apply border-none;
    }

    .pagination {
        @apply flex justify-center items-center gap-2 mt-4;
    }

    .no-drag {
        -ms-user-select: none;
        -moz-user-select: -moz-none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        user-select: none;
    }

    button[disabled] {
        @apply bg-light-green text-pure-white opacity-50;
    }

    select {
        @apply bg-pure-white;
    }

    select[disabled], input[disabled] {
        @apply bg-border-light;
    }

    .inputWrap:nth-child(3n) {
        @apply mr-0;
    }

    input {
        @apply focus:outline-0 border border-input-border-color;
    }

    input.inputNoBorder {
        border: none !important;
        border-bottom: 1px solid #538a40 !important;

    }

    input[type=checkbox], input[type=radio] {
        accent-color:#538a40 ;
    }

    select {
        @apply border border-input-border-color;
    }

    textarea {
        @apply border border-input-border-color;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    input[type='checkbox'] {
        @apply mr-1 w-4 h-4 accent-light-green;
    }

    select option[value=""][disabled] {
        display: none;
    }

    select:invalid {
        color: #979797;
    }

    input[type="file"] {
        position: relative;
    }

    .tooltipContent {
        border-radius: 0 16px 16px 16px;
    }

    .tooltipContent::after {
        content: '';
        position: absolute;
        left: 0;
        top: 100%;
        width: 0;
        height: 0;
        border: 21px solid transparent;
        border-right-color: #30858d;
        border-left: 0;
        border-top: 0;
        display: none;
        margin-top: -10.5px;
        margin-left: -21px;
    }

    .tooltipContent:hover>.tooltip,
    .tooltipContent:active>.tooltip {
        top: 38px;
        right: -225px;
        @apply web:block phone:hidden;
    }
    .tooltipHidden:hover>.tooltip,
    .tooltipHidden:active>.tooltip {
        @apply hidden;
    }
    .slick-slide img {
        @apply inline-block !important;
    }
    .slick-dots{
        @apply overflow-hidden;
    }
    .slick-dots li.slick-active button:before {
        margin-top: .5rem;
        color:#538a40 !important;
    }

    .slick-dots li button:before {
        margin-top: .5rem;
        color:#538a40 !important;

    }
}

@layer components {
    .toast-container {
       @apply bg-[rgba(0,0,0,.4)] fixed bottom-24 left-8 py-3 px-4 rounded-lg min-w-[320px] box-border z-[999999]
    }
    .acInput {
        @apply rounded-lg p-2 text-sm focus:outline-none w-full;
    }
    .acHeight {
        @apply h-[37px]
    }
    .acSubInput {
        @apply rounded-lg p-2 mt-2 text-sm focus:outline-none w-full border border-input-border-color h-[37px];
    }
    .acSubMinInput {
        @apply rounded-lg mt-2 web:w-[304px] phone:w-full p-2 text-sm focus:outline-none border border-input-border-color h-[37px];
    }
    .touristInput {
        @apply p-2 rounded-lg text-sm border border-input-border-color w-full mt-2;
    }
    .extraInput {
        @apply p-2 rounded-lg text-sm border border-input-border-color web:w-76 phone:w-full mt-2;
    }

    .toggleWrap {
        position: relative;
        cursor: pointer;
    }

    .toggleWrap>.toggle-container {
        width: 50px;
        height: 16px;
        border-radius: 30px;
        background-color: rgb(233, 233, 234);
    }

    .toggleWrap>.toggle--checked {
        @apply bg-main-green;
        transition: 0.5s
    }

    .toggleWrap>.toggle-circle {
        position: absolute;
        top: 1px;
        left: 1px;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background-color: rgb(255, 254, 255);
        transition: 0.5s
    }

    .toggleWrap>.toggle--checked {
        left: 35px;
        transition: 0.5s
    }

    .statePick {
        position: relative;
        background: #538a40;
        color: white;
    }

    .statePick::after {
        content: '';
        z-index: 20000;
        position: absolute;
        right: 0;
        top: 50%;
        width: 0;
        height: 0;
        border: 12px solid transparent;
        border-left-color: #538a40;
        border-right: 0;
        margin-top: -12px;
        margin-right: -12px;
    }

    .noScroll::-webkit-scrollbar {
        @apply hidden
    }
    .rdp-months {
        @apply web:justify-center phone:justify-start z-100 relative bg-pure-white rounded-lg;
    }

    .rdp {
        @apply web:m-4 phone:m-2 !important;
    }
    .rdp-table {
        border: 0px;
    }

    .react-datepicker {
        @apply w-full web:max-w-[920px] border-border-light !important;
    }

    .react-datepicker__month-container {
        @apply web:w-full web:max-w-[920px] phone:w-full border-border-light !important;
    }

    .react-datepicker__week {
        @apply justify-between flex;
    }

    .react-datepicker__day,
    .react-datepicker__time-name {
        @apply web:w-full web:h-[144px] phone:w-[51px] phone:h-[59px] !important;
    }

    .react-datepicker__day-names {
        /* 일 월 화 수 목 . . . . */
        @apply flex justify-between px-[0.4rem];
    }

    .react-datepicker__day-name:nth-child(1) {
        @apply text-red !important;
    }

    .react-datepicker__day-name:nth-child(7) {
        @apply text-blue !important;
    }

    .react-datepicker__day-name {
        /* 일 월 화 수 목 . . . . */
        @apply web:w-screen phone:w-[51px] !important;
    }

    .react-datepicker__current-month {
        @apply py-8 text-xl !important;
    }

    .react-datepicker__header {
        @apply bg-pure-white border-[#ebebeb] !important;
    }

    .react-datepicker__day--keyboard-selected,
    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range {
        @apply bg-light-green border-2 border-light-green rounded-lg !important;
    }

    .react-datepicker__day--disabled {
        color: #ebebeb !important;
    }
    .react-datepicker__day .react-datepicker__day--disabled .react-datepicker__day--weekend {
        @apply bg-red !important;
    }

    .react-datepicker__navigation-icon::before {
        border-color: #538a40 !important;
    }

    .react-datepicker_custom_color {
        color: black !important;
        background: repeating-linear-gradient(-45deg, #000, #000 1px, #eee 0 20px);
        border: 1px solid black !important;
        border-radius: 8px;
    }
    .react-datepicker_custom_color:hover {
        @apply hover:border-light-green border-[3px] !important;
    }

    .react-datepicker__day--today {
        @apply border rounded-lg border-light-green !important;
    }

    .typeHover {
        @apply web:block phone:hidden;
    }
    
}